@import '@bryntum/core-thin/core.stockholm.css';
@import '@bryntum/grid-thin/grid.stockholm.css';
@import '@bryntum/scheduler-thin/scheduler.stockholm.css';
@import '@bryntum/schedulerpro-thin/schedulerpro.stockholm.css';
@import '@bryntum/gantt-thin/gantt.stockholm.css';
/*
  In order to solve the Ag Grid icons issue
  we moved the Ag grid CSS initialization higher up in the order
  and now we get it here.
  This should solve this issue for now,
  in the future once we moved to the new Theme API
  we will remove the imports of this CSS.
*/
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  z-index: 0;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Prevents focus ring for Chrome and safari: https://stackoverflow.com/questions/7538771/what-is-webkit-focus-ring-color*/
g,
path,
polygon {
  outline: none;
}
